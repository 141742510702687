import React from 'react'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar';

import Header from './Header'
import Footer from './Footer'

import { FormProvider } from '../components/FormContext'
import { BookingProvider } from '../components/BookingContext';
import { firstLetter } from '../utils/boiler';
import { translate } from '../translations/utils';

const Layout = pageProps => {
  const headerProps = {
    nav: {
      classes: pageProps.nav && pageProps.nav.classes,
      fixed: pageProps.nav && pageProps.nav.fixed,
      color: pageProps.nav && pageProps.nav.color,
      light: pageProps.nav && pageProps.nav.light,
      dark: pageProps.nav && pageProps.nav.dark
    },
    headerClasses: pageProps.headerClasses,
    locale: pageProps.locale,
    defaultLocale: pageProps.defaultLocale,
    showOnlyLogo: pageProps.showOnlyLogo
  }

  const footerProps = {
    locale: pageProps.locale,
    defaultLocale: pageProps.defaultLocale,
  }

  const { locale, defaultLocale } = pageProps

  return (
    <div style={{ paddingTop: pageProps.noPaddingTop ? '0' : '77px' }} className={pageProps.className}>
      <Head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:ital,wght@0,300;0,400;0,700;1,400&display=swap" />
        <link rel="icon" href="/favicon.png" />
        <link href='https://use.fontawesome.com/releases/v5.8.1/css/all.css' rel="stylesheet" />
        <link href='https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.3.8/css/swiper.min.css' rel="stylesheet" />
        <title>{pageProps.title ? `${firstLetter(pageProps.title)} - Gurubay` : translate("index_page_title", locale, defaultLocale)}</title>
        <meta name="thumbnail" content="https://gurubay-front-res.s3.fr-par.scw.cloud/logo-square-padded.png" />
        <meta property="og:image" content={`${process.env.NEXT_PUBLIC_HOST}/content/img/oglogo.png`} key="og:image" />
        {pageProps.title ? <meta name="robots" content="nosnippet" /> : <meta name="description" content={translate("index_page_meta_desc", locale, defaultLocale)} />}
      </Head>
      <NextNProgress color="#636aee" options={{ showSpinner: false }} />
      {!pageProps.hideHeader &&
        <Header {...headerProps} />
      }

      {pageProps.listingForm || pageProps.bookingForm ?
        <React.Fragment>
          {pageProps.listingForm &&
            <FormProvider>
              <main>{pageProps.children}</main>
            </FormProvider>
          }
          {pageProps.bookingForm &&
            <BookingProvider>
              <main>{pageProps.children}</main>
            </BookingProvider>
          }
        </React.Fragment>
        :
        <main>{pageProps.children}</main>
      }

      {!pageProps.hideFooter &&
        <Footer {...footerProps} />
      }
    </div>
  )
}

export default Layout