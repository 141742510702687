
import React from 'react'

export const HeaderCollapsedContext = React.createContext({
    collapsed: null,
    setCollapsed: () => null,
    isDropdownOpen: null,
    setIsDropdownOpen: () => null,
    navBarColor: null,
    setNavBarColor: () => null,
    navBarDark: null,
    setNavBarDark: () => null,
    navBarLight: null,
    setNavBarLight: () => null,
})

export const HeaderCollapsedProvider = ({ collapsed, setCollapsed, isDropdownOpen, setIsDropdownOpen, navBarColor, setNavBarColor, navBarDark, setNavBarDark, navBarLight, setNavBarLight, children }) => {
    return <HeaderCollapsedContext.Provider value={{ collapsed, setCollapsed, isDropdownOpen, setIsDropdownOpen, navBarColor, setNavBarColor, navBarDark, setNavBarDark, navBarLight, setNavBarLight }}>{children}</HeaderCollapsedContext.Provider>
}