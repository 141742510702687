import React from 'react'
import Link from 'next/link'
import Router from 'next/router'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    Row,
    Col,
    Badge
} from 'reactstrap'

import UseWindowSize from '../hooks/UseWindowSize'

import ActiveLink from './ActiveLink'
import Logo from '../../public/content/svg/logo.svg'

import menu from '../data/menu.json'

import userMenu from '../data/user-menu.json'

import { UserContext } from '../contexts/UserContext'
import { translate } from '../translations/utils'
import { HeaderCollapsedContext } from '../contexts/HeaderCollapsedContext'
import { isBrowser } from '../utils/boiler'

import { UsersApi } from "typescript-axios";
import { getAxiosParams } from '../utils/client'
import { logout } from '../utils/session'
import Image from "../components/CustomImage"


const USER_SERVICE = new UsersApi(getAxiosParams())
const getNotifIcon = () => <i className="fa fa-circle fa-xs text-primary ml-2" />

const Header = props => {
    const { locale, defaultLocale } = props
    const { collapsed, setCollapsed, isDropdownOpen, navBarColor, navBarLight, navBarDark } = React.useContext(HeaderCollapsedContext)
    const [dropdownOpen, setDropdownOpen] = React.useState({})
    const [searchFocus, setSearchFocus] = React.useState(false)
    const [dropdownAnimate, setDropdownAnimate] = React.useState(false)
    const [parentName, setParentName] = React.useState(false)
    const { loggedUser, setLoggedUser } = React.useContext(UserContext)
    const size = UseWindowSize()
    const [isGuru, setIsGuru] = React.useState(loggedUser?.is_guru)

    React.useEffect(() => {
        if (loggedUser)
            USER_SERVICE.readUserMeApiV1UsersMeGet()
                .then(res => setLoggedUser(res.data))
                .catch(e => {
                    try {
                        if (e.response.status == 401 ||
                            e.response.status == 404 ||
                            e.response.status == 403) {
                            logout(setLoggedUser)
                        }
                    } catch (innerError) {
                        console.error(innerError)
                        console.error(JSON.stringify(e))
                        console.error("there was an unexpected error here")
                        console.error(e, e.stack)
                    }

                })
    }, [])

    React.useEffect(
        () => {
            setIsGuru(loggedUser?.is_guru)
        }, [loggedUser]
    )

    React.useEffect(
        () => {
            if (!isDropdownOpen)
                setDropdownOpen({})
        }, [isDropdownOpen]
    )

    const toggleDropdown = (name) => {
        setDropdownOpen({ ...dropdownOpen, [name]: !dropdownOpen[name] })
    }

    const onLinkClick = (parent) => {
        size.width < 991 && setCollapsed(!collapsed)
        setParentName(parent)
    }

    // highlight not only active dropdown item, but also its parent, i.e. dropdown toggle
    const highlightDropdownParent = allMenus => {
        const key = loggedUser ? (isGuru ? "guru" : "user") : "anonymous"
        const menu = allMenus[key]
        menu.map(item => {
            item.dropdown && item.dropdown.map(dropdownLink => {
                dropdownLink.link && dropdownLink.link === Router.route && setParentName(item.title)
                dropdownLink.links && dropdownLink.links.map(link => link.link === Router.route && setParentName(item.title))
            }
            )
            item.megamenu && item.megamenu.map(megamenuColumn =>
                megamenuColumn.map(megamenuBlock =>
                    megamenuBlock.links.map(dropdownLink => {
                        if (dropdownLink.link === Router.route) {
                            dropdownLink.parent ? setParentName(dropdownLink.parent) : setParentName(item.title)
                        }
                    })
                )
            )
            item.link === Router.route && setParentName(item.title)
        })

    }
    React.useEffect(() => highlightDropdownParent(menu), [])

    const userMenuDropdown = dropdown => {
        const key = isGuru ? "guru" : "user"
        return dropdown[key].map(dropdownItem => {
            let icon = ""
            if (dropdownItem.link == '/inbox' && loggedUser.n_chats_no_read) {
                icon = getNotifIcon()
            }
            return <ActiveLink key={dropdownItem.title} activeClassName="active" href={dropdownItem.link} passHref>
                <DropdownItem id={dropdownItem.id} onClick={() => onLinkClick(dropdownItem.title)}>
                    {translate(dropdownItem.title, locale, defaultLocale)}{icon}
                </DropdownItem>

            </ActiveLink>
        })
    }

    const topMenu = allMenus => {
        const key = loggedUser ? (isGuru ? "guru" : "user") : "anonymous"
        const menu = allMenus[key]
        return menu && menu.map(item =>
            item.dropdown || item.megamenu ?
                (!loggedUser && !item.hideToAnonymous) || (loggedUser && !item.hideToLoggedUser) ?
                    <Dropdown
                        nav
                        inNavbar
                        key={item.title}
                        className={item.position ? `position-${item.position}` : ``}
                        isOpen={dropdownOpen[item.title]}
                        toggle={() => toggleDropdown(item.title)}
                    >
                        <DropdownToggle
                            nav
                            caret
                            onClick={() => setDropdownAnimate({ ...dropdownAnimate, [item.title]: !dropdownOpen[item.title] })}
                            className={"text-capitalize" + (parentName === item.title ? 'active' : '')}
                        >
                            {translate(item.title, locale, defaultLocale)}
                        </DropdownToggle>
                        <DropdownMenu className={`${dropdownAnimate[item.title] === false ? 'hide' : ''} ${item.megamenu ? 'megamenu py-lg-0' : ''}`}>
                            {item.dropdown &&
                                item.dropdown.map(dropdownItem =>
                                    dropdownItem.links ?
                                        <React.Fragment key={dropdownItem.title}>
                                            <h6 className="dropdown-header font-weight-normal text-first-letter">
                                                {translate(item.title, locale, defaultLocale)}
                                            </h6>
                                            {dropdownItem.links.map(link =>
                                                <ActiveLink key={link.title} activeClassName="active" href={link.link} passHref>
                                                    <DropdownItem onClick={() => onLinkClick(item.title)}>
                                                        {link.title}
                                                        {link.new &&
                                                            <Badge color="info-light" className="ml-1 mt-n1">New</Badge>
                                                        }
                                                    </DropdownItem>
                                                </ActiveLink>
                                            )}
                                        </React.Fragment>
                                        :
                                        <ActiveLink key={dropdownItem.title}
                                            activeClassName="active text-first-letter" href={dropdownItem.link} passHref>
                                            <DropdownItem onClick={() => onLinkClick(item.title)}>
                                                {translate(dropdownItem.title, locale, defaultLocale)}
                                                {dropdownItem.new &&
                                                    <Badge color="info-light" className="ml-1 mt-n1">New</Badge>
                                                }
                                            </DropdownItem>
                                        </ActiveLink>
                                )
                            }
                            {item.megamenu &&
                                <Row>
                                    <Col lg="9">
                                        <Row className="p-3 pr-lg-0 pl-lg-5 pt-lg-5">
                                            {item.megamenu.map((megamenuItem, index) =>
                                                <Col key={index} lg="3">
                                                    {megamenuItem.map((block, index) =>
                                                        <React.Fragment key={index}>
                                                            <h6 className="test-first-letter">{block.title}</h6>
                                                            <ul className="megamenu-list list-unstyled">
                                                                {block.links.map(link =>
                                                                    <li
                                                                        key={link.title}
                                                                        className="megamenu-list-item">
                                                                        <ActiveLink activeClassName="active" href={link.link} as={link.as} passHref>
                                                                            <DropdownItem className="megamenu-list-link" onClick={() => link.parent ? onLinkClick(link.parent) : onLinkClick(item.title)}>
                                                                                {link.title}
                                                                                {link.new &&
                                                                                    <Badge color="info-light" className="ml-1 mt-n1">New</Badge>
                                                                                }
                                                                            </DropdownItem>
                                                                        </ActiveLink>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </React.Fragment>
                                                    )}
                                                </Col>
                                            )}
                                        </Row>
                                        {item.services &&
                                            <Row
                                                className="megamenu-services d-none d-lg-flex pl-lg-5"
                                            >
                                                {item.services.map(service =>
                                                    <Col
                                                        key={service.title}
                                                        xl="3"
                                                        lg="6"
                                                        className="d-flex"
                                                    >
                                                        <div className="megamenu-services-item">
                                                            <svg className="svg-icon megamenu-services-icon"
                                                            >
                                                                <use xlinkHref={service.icon}> </use>
                                                            </svg>
                                                            <div>
                                                                <h6 className="test-first-letter">{service.title}</h6>
                                                                <p className="mb-0 text-muted text-sm">{service.content}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )}

                                            </Row>
                                        }



                                    </Col>
                                    {item.image &&
                                        <Col lg="3" className="d-none d-lg-block">
                                            <img src={item.image} alt="" className="bg-image" />
                                        </Col>
                                    }
                                </Row>
                            }
                        </DropdownMenu>
                    </Dropdown>
                    :
                    ''
                :
                (!loggedUser && !item.hideToAnonymous) || (loggedUser && !item.hideToLoggedUser) ?
                    <NavItem
                        id={item.id}
                        key={item.title}
                        className={item.button ? 'mt-2 mt-lg-0 ml-lg-3 d-xl-inline-block' : ''}>
                        {item.button ? // here if the user is already on the page linked by button, we want to reload with query args - this is particularly important for not breaking onboarding
                            item.showToLoggedUser !== false && <ActiveLink href={window.location.pathname === item.link ? window.location : item.link}><a className={`btn btn-primary ${window.location.pathname === item.link ? 'disabled' : ''}`} onClick={() => onLinkClick(item.title)}>{translate(item.title, locale, defaultLocale)}</a></ActiveLink>
                            :
                            <ActiveLink href={item.link} passHref>
                                <NavLink className="text-first-letter" onClick={() => onLinkClick(item.title)}>
                                    {translate(item.title, locale, defaultLocale)}
                                    {((item.link == '/inbox' && loggedUser.n_chats_no_read) ||
                                        (item.link == '/classes-guru' && loggedUser.n_open_requests_with_me)) ? getNotifIcon() : ''}
                                </NavLink>
                            </ActiveLink>
                        }
                    </NavItem>
                    :
                    ''
        )
    }

    return (
        <header className={`header ${props.headerClasses ? props.headerClasses : ''}`}>
            <Navbar
                color={navBarColor}
                light={navBarLight && true}
                dark={navBarDark && true}
                fixed={props.nav.fixed ? props.nav.fixed : "top"}
                expand="lg"
                className={(props.nav.classes && navBarColor != "transparent") ? props.nav.classes : ""}
            >
                <Container fluid={true}>
                    <div className="d-flex align-items-center">
                        <Link href="/" passHref>
                            <a className="py-1 navbar-brand">
                                {/* <Logo height={40}/> */}
                                <Image src={Logo.src} width={Logo.width} height={Logo.height} alt="Gurubay" />
                            </a>
                        </Link>
                    </div>
                    {!props.showOnlyLogo && <>
                        <NavbarToggler
                            onClick={() => setCollapsed(!collapsed)}
                            className="navbar-toggler-right"
                        >
                            <i className="fa fa-bars" />
                        </NavbarToggler>
                        <Collapse
                            isOpen={collapsed}
                            navbar
                        >
                            {isBrowser() && <Nav navbar className="ml-auto">
                                {topMenu(menu)}
                                {loggedUser && userMenu && userMenu.map(item =>
                                    <Dropdown
                                        // nav need to remove this for not having color border but why
                                        inNavbar
                                        key={item.title}
                                        className={item.type === "avatar" ? "ml-lg-3 mt-2 mt-md-0" : ""}
                                        isOpen={isDropdownOpen || dropdownOpen[item.title]}
                                        toggle={() => toggleDropdown(item.title)}
                                    >
                                        <DropdownToggle
                                            nav
                                            className="text-capitalize"
                                            style={item.type === "avatar" && { padding: 0 }}
                                            onClick={() => setDropdownAnimate({ ...dropdownAnimate, [item.title]: !dropdownOpen[item.img] })}
                                        >
                                            {item.type === "avatar" ?
                                                <img src={loggedUser.avatar ?? '/content/img/avatar/dummy-user.jpg'}
                                                    alt={item.title}
                                                    className="avatar avatar-sm avatar-border-white mr-2" />
                                                :
                                                translate(item.title, locale, defaultLocale)
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu className={dropdownAnimate[item.title] === false ? 'hide' : ''} right>
                                            {item.dropdown && userMenuDropdown(item.dropdown)}
                                        </DropdownMenu>
                                    </Dropdown>
                                )}
                            </Nav>}
                        </Collapse>
                    </>
                    }
                </Container>
            </Navbar>
        </header >
    )
}

export default Header