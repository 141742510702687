import axios from 'axios'
import { LoginApi, UsersApi } from "typescript-axios";
import { getAxiosParams } from '../utils/client'
import { isBrowser } from './boiler'
import * as fbq from '../lib/fpixel'

const USER_SERVICE = new UsersApi(getAxiosParams())
const LOGIN_SERVICE = new LoginApi(getAxiosParams())

export function redirectServer(destination, permanent = false) {
    return {
        redirect: {
            permanent,
            destination,
        },
        props: {},
    }
}

export function redirectToLoginServer(req) {
    return redirectServer(`/login?redirectUrl=${req}`, false)
}

export function getAuthorizationHeadersServer(req, res) {
    const Cookies = require('cookies')
    const cookies = new Cookies(req, res)
    const token = cookies.get('access-token') || ''
    if (!token) {
        return null
    }
    return { 'Authorization': `Bearer ${token}` }
}

export function withSession(handler) {
    return function withSessionHandler(...args) {
        //https://github.com/vvo/next-iron-session/blob/master/lib/index.js#L99
        const { req, res, resolvedUrl } = args[0]
        const headers = getAuthorizationHeadersServer(req, res)
        if (!headers) {
            return redirectToLoginServer(resolvedUrl)
        }
        args[0].options = { headers }

        return handler(...args).catch(e => {
            if(e.response?.status == 403) {
                return redirectServer(`/logout?redirectUrl=${resolvedUrl}`, false)
            } else {
                console.error(e)
                return redirectServer(`/`, false)
            }
        })
    }
}

export async function fetchLoggedUser() {
    if (typeof window === 'undefined') {
        return null
    }
    const user = await USER_SERVICE.readUserMeApiV1UsersMeGet()
        .then(res => res.data)
        .catch(e => null)
    return user
}

export function fbLogout() {
    if (isBrowser()) {
        try {
            window.FB?.getLoginStatus(function (response) {
                if (response && response.status === 'connected') {
                    FB.logout()
                }
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export async function logout(setLoggedUser) {
    fbLogout()
    await axios.get('/api/logout/')
        .catch(e => console.error(`failed logout ${e}`))
        .finally(() => {
            setLoggedUser(null)
        })
}



export async function createUserSso(token, onError, onSuccess, guruParams = null) {
    guruParams = guruParams ?? {}
    // phone_number?: string;
    // activity?: number | Array<number>;
    // country_of_residence: string;
    // nationality: string;

    onError = onError ?? console.error
    try {
        fbq.event("Lead", { content_category: "USER" })
        const res = await USER_SERVICE.createUserSsoFacebookApiV1UsersSsoFacebookPost({ token, ...guruParams })
            .catch(e => e.response)

        if (res.status < 300 && res.status >= 200) {
            const loginRes = await LOGIN_SERVICE.loginAccessTokenSsoApiV1LoginAccessTokenSsoPost("facebook", token)
            if (loginRes.status < 300 && loginRes.status >= 200) {
                onSuccess(res.data)
            } else {
                onError(loginRes)
            }
        } else {
            onError(res)
        }
    } catch (e) {
        fbLogout()
        console.error(`failed upload ${e}`)
    }
}