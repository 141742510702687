import { Badge } from "reactstrap"
import axios from "axios"
import Link from "next/link";
import imageCompression from 'browser-image-compression';
import { getFormattedDateTime, offsetDate } from "./datetime";
import moment from "moment"
import debounce from "lodash/debounce"
import ReactHtmlParser from 'react-html-parser'

export function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

export function isImage(file) {
    return (file && file['type'].split('/')[0] === 'image')
}

export function isBrowser() {
    return typeof window !== 'undefined'
}

export function firstLetter(str) {
    if (!str)
        return ''
    return str.charAt(0).toUpperCase() + str.substring(1)
}

export function substrWithDots(str, n) {
    return str?.length > n ? str.substring(0, n - 3) + '...' : str
}

const CURRENCY_SIGNS = {
    EUR: "€",
    USD: "$",
    ILS: "₪",
}
export function getCurrencySign(currency) {
    return CURRENCY_SIGNS[currency.toUpperCase()]
}

export function getLoginRedirectLink(loggedUser, redirectUrl, children, isGuru = null) {
    if (loggedUser) {
        return <Link href={redirectUrl}>
            {children}
        </Link>
    } else {
        return <Link
            href={{
                pathname: '/login',
                query: { redirectUrl, ...(isGuru === null ? {} : { isGuru: isGuru ? 1 : 0 }) }
            }}
        >
            {children}
        </Link>
    }
}

export async function compressImage(imageFile, maxSizeMB = 2) {
    const options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    try {
        // this shit requires https://caniuse.com/?search=globalThis
        return await imageCompression(imageFile, options);
    } catch (error) {
        console.log(error);
        return imageFile
    }
}

export function isInViewport(component, offset = 0) {
    if (!component) {
        return false
    } else {
        const top = component.getBoundingClientRect().top;
        const isVisible = (top + offset) >= 0 && (top - offset) <= window.innerHeight
        return isVisible
    }
}

export function shuffle(array) {
    // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function getChatTitle(chat, other, t) {
    if (chat.listing) {
        const { date, time } = getFormattedDateTime(chat.listing.datetime_start)
        const title = getListingTitle(chat.listing, t, other.id != chat.listing.owner.id)
        return `${title}, ${date}, ${time}`
    } else
        return other.firstname
}


export function gup(url, name) {
    name = name.replace(/[[]/, "\[").replace(/[]]/, "\]");
    var regexS = "[\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    if (results == null)
        return "";
    else
        return results[1];
}

export function timeToInt(time) {
    return time.hour() * 60 + time.minute()
}


export function availabilitiesToArray(availabilities) {
    const array = {}
    availabilities?.map(avail => {
        const start = moment(offsetDate(avail.start))
        avail = { ...avail, start }
        const startOf = moment(start).startOf("day")
        if (array[startOf]) {
            array[startOf].push(avail)
        } else {
            array[startOf] = [avail]
        }
    })
    return array
}

export async function getIp() {
    let ip = await axios.get("https://www.cloudflare.com/cdn-cgi/trace").then(res => {
        res = res.data.trim().split('\n').reduce(function (obj, pair) {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
        }, {});
        return res.ip
    }).catch(e => {
        console.error(e)
        return null
    })
    if (!ip) {
        ip = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=bff7cee4e5714700989b60c09ca8d084")
            .then(res => res.data.ip_address)
    }
    return ip
}

export function getBrowserInfo() {
    return {
        Language: navigator.language,
        JavaEnabled: navigator.javaEnabled(),
        JavascriptEnabled: true,
        ColorDepth: window.screen.colorDepth,
        ScreenHeight: window.screen.height,
        ScreenWidth: window.screen.width,
        TimeZoneOffset: (new Date()).getTimezoneOffset().toString(),
        UserAgent: navigator.userAgent,
    }
}

export function handleOauthWindow(win, goTo, redirectUri, findKey, onSuccess, onError) {
    win.location.href = goTo
    var pollTimer = window.setInterval(function () {
        if (win.closed) {
            window.clearInterval(pollTimer);
            onError && onError()
            return
        }
        try {
            if (win.document.URL.indexOf(redirectUri) != -1) {
                window.clearInterval(pollTimer);
                var url = win.document.URL;
                const code = gup(url, findKey);
                if (code) {
                    onSuccess && onSuccess(code)
                } else {
                    onError && onError()
                }
                win.close();
            }
        } catch (e) {
            if (win.closed) {
                window.clearInterval(pollTimer);
                onError && onError()
                return
            }
        }
    }, 100);
}

export function getListingTitle(listing, t, asGuru = true) {
    return firstLetter(listing.is_on_request ? t("listing_req_row_title",
        listing.activity.name,
        asGuru ? listing.request_owner.firstname : listing.owner.firstname
    ) : listing.title)
}

export function asyncDebounce(func, wait, opts) {
    const debounced = debounce((resolve, reject, args) => {
        func(...args).then(resolve).catch(reject);
    }, wait, opts);
    return (...args) =>
        new Promise((resolve, reject) => {
            debounced(resolve, reject, args);
        });
}

export function getShareGuruCodeUrl(guru) {
    return `${process.env.NEXT_PUBLIC_HOST}/u/${guru.guru_code}`
}

export function getRecurrenceString(frequency, t) {
    let out = ""
    if (frequency == "daily") {
        return t("recurring_daily")
    } else if (frequency == "weekly") {
        return t("recurring_weekly")
    } else if (frequency == "bi_monthly") {
        return t("recurring_bi_monthly")
    }
    return ""
}

export function getLocationString(guru, t) {
    const locs = []
    if (guru?.is_available_online || guru?.has_open_online_classes)
        locs.push(firstLetter(t("online")))
    if (guru?.current_location)
        locs.push(guru.current_location.city)
    if (guru?.class_available_location && (!guru.current_location || guru.current_location.id != guru?.class_available_location.id))
        locs.push(guru.class_available_location.city)
    return locs
}

export function getFirstClassDiscountString(first_class_discount, t) {
    return ReactHtmlParser(
        first_class_discount == 100 ? t("first_class_discount_free") :
            t("first_class_discount_%", first_class_discount)
    )
}

export function getFirstClassDiscountBadge(first_class_discount, t) {
    return (first_class_discount != null && first_class_discount > 0) && <h5>
        <Badge color="success">
            {getFirstClassDiscountString(first_class_discount, t)}
        </Badge>
    </h5>
}

export function getParticipantsBadge(listing, t) {
    const hasSpace = listing.num_participants < listing.max_participants
    const color = hasSpace ? "primary-light" : "light"
    const text = hasSpace ? t("listing_has_spot_left", listing.max_participants - listing.num_participants) : t("listing_has_no_spot_left")
    return <Badge
        color={color}
        style={{ marginRight: '5px' }}><i className="fas fa-users fa-fw text-primary mr-2" />
        {text}
    </Badge>
}
