import React from 'react'
import Layout from '../components/Layout'
import 'moment/locale/fr'

import 'leaflet/dist/leaflet.css'
import 'react-image-lightbox/style.css'
import '../scss/style.default.scss'
import 'react-phone-input-2/lib/style.css'
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import { UserProvider } from '../contexts/UserContext'
import { ScriptProvider } from '../contexts/ScriptContext'
import { HeaderCollapsedProvider } from '../contexts/HeaderCollapsedContext'
import { isBrowser } from '../utils/boiler'
import { getClientLocation } from '../utils/client'
import { useLoadScript } from "@react-google-maps/api";
import Script from 'next/script'
import { useRouter } from 'next/router'
import * as fbq from '../lib/fpixel'

function getLoggedUser() {
  if (typeof window === 'undefined')
    return null
  try {
    let loggedUser = JSON.parse(localStorage.getItem('loggedUser'))
    return loggedUser
  } catch (e) {
    localStorage.removeItem('loggedUser')
    return null
  }
}

function getGuruCode() {
  if (typeof window === 'undefined')
    return null
  try {
    return localStorage.getItem('guruCode')
  } catch (e) {
    localStorage.removeItem('guruCode')
    return null
  }
}

function getDefaultLocation() {
  if (typeof window === 'undefined')
    return null
  try {
    let defaultLocation = JSON.parse(localStorage.getItem('defaultLocation'))
    return defaultLocation
  } catch (e) {
    localStorage.removeItem('defaultLocation')
    return null
  }
}

function getCalendarView() {
  if (typeof window === 'undefined')
    return null
  try {
    let calendarView = JSON.parse(localStorage.getItem('calendarView'))
    return calendarView
  } catch (e) {
    localStorage.removeItem('calendarView')
    return null
  }
}

function getCalendarBound(key) {
  if (typeof window === 'undefined')
    return null
  try {
    let bound = localStorage.getItem(key)
    bound = bound ? new Date(Date.parse(bound)) : undefined
    return bound
  } catch (e) {
    localStorage.removeItem(key)
    return null
  }
}


const LIBRARIES = ["places"]

// This default export is required in a new `pages/_app.js` file.
const _app = ({ Component, pageProps, err }) => {
  const [guruCode, setGuruCode] = React.useState(getGuruCode())
  const [placesLoaded, setPlacesLoaded] = React.useState(false)
  const [loggedUser, setLoggedUser] = React.useState(getLoggedUser())
  const [remainingTickets, setRemainingTickets] = React.useState(null)
  const [defaultLocation, setDefaultLocation] = React.useState(getDefaultLocation())
  const [calendarView, setCalendarView] = React.useState(getCalendarView() ?? "work_week")
  const [calendarMin, setCalendarMin] = React.useState(getCalendarBound("calendarMin"))
  const [calendarMax, setCalendarMax] = React.useState(getCalendarBound("calendarMax"))
  const [isGoogleCalendarSignedIn, setIsGoogleCalendarSignedIn] = React.useState(null)
  const [isInstaSignedIn, setIsInstaSignedIn] = React.useState(null)
  const [headerCollapsed, setHeaderCollapsed] = React.useState(false)
  const [isHeaderDropdownOpen, setIsHeaderDropdownOpen] = React.useState(false)
  const [navBarColor, setNavBarColor] = React.useState(pageProps.nav?.color ? pageProps.nav.color : "white")
  const [navBarLight, setNavBarLight] = React.useState(pageProps.nav?.light)
  const [navBarDark, setNavBarDark] = React.useState(pageProps.nav?.dark)

  const router = useRouter()

  React.useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])


  React.useEffect(
    () => {
      // on page change we need to discard what was in the context
      if (pageProps.nav) {
        setNavBarColor(pageProps.nav?.color ? pageProps.nav.color : "white")
        setNavBarLight(pageProps.nav?.light)
        setNavBarDark(pageProps.nav?.dark)
      }
    }, [pageProps.nav]
  )

  React.useEffect(
    () => {
      if (router.isReady && router.query.code) {
        if (router.query.code.length == 8) {
          // FIXME: hotfix, SSO also uses this code qry string parameter...
          setGuruCode(router.query.code)
        }
      }
    }, [router.isReady]
  )

  React.useEffect(() => {
      const interval = setInterval(() => {
        if (isBrowser()) {
          getClientLocation().then(defLoc => setDefaultLocation(defLoc))
        }
      }, 60000);
      return () => clearInterval(interval);
  }, [])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCPF23ceF8bwHOtGIX1eo8lmzpeIy9A_aQ",
    libraries: LIBRARIES
  });

  if (isLoaded && !placesLoaded) {
    setPlacesLoaded(true)
  }

  // override the logged fields, not object in case the new user has less attributes
  return <>
    {/* Global Site Code Pixel - Facebook Pixel */}
    <Script
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${fbq.FB_PIXEL_ID});
        `,
      }}
    /><HeaderCollapsedProvider
      collapsed={headerCollapsed}
      setCollapsed={setHeaderCollapsed}
      isDropdownOpen={isHeaderDropdownOpen}
      setIsDropdownOpen={setIsHeaderDropdownOpen}
      navBarColor={navBarColor}
      setNavBarColor={setNavBarColor}
      navBarLight={navBarLight}
      setNavBarLight={setNavBarLight}
      navBarDark={navBarDark}
      setNavBarDark={setNavBarDark}
    >
      <ScriptProvider placesLoaded={placesLoaded} setPlacesLoaded={setPlacesLoaded}>
        <UserProvider
          defaultLocation={defaultLocation}
          setDefaultLocation={setDefaultLocation}
          remainingTickets={remainingTickets}
          setRemainingTickets={setRemainingTickets}
          calendarView={calendarView}
          setCalendarView={setCalendarView}
          calendarMin={calendarMin}
          setCalendarMin={setCalendarMin}
          calendarMax={calendarMax}
          setCalendarMax={setCalendarMax}
          isGoogleCalendarSignedIn={isGoogleCalendarSignedIn}
          setIsGoogleCalendarSignedIn={setIsGoogleCalendarSignedIn}
          isInstaSignedIn={isInstaSignedIn}
          setIsInstaSignedIn={setIsInstaSignedIn}
          guruCode={guruCode}
          setGuruCode={setGuruCode}
          loggedUser={loggedUser}
          setLoggedUser={v => {
            if (v === null) {
              setGuruCode(null)
            }
            setLoggedUser(v === null ? null : { ...loggedUser, ...v })
          }}>
          <Layout {...pageProps}>
            <Component {...pageProps} err={err} />
          </Layout>
        </UserProvider>
      </ScriptProvider>
    </HeaderCollapsedProvider>
  </>
}

export default _app