import React from 'react'
import Link from 'next/link'

const LanguageSwitch = props => {
    const { locale } = props
    return (
        <div style={{ display: 'flex' }}>
            <Link href="/" locale="fr">
                <div className={`mr-1 test-first-letter ${locale == 'fr' ? 'font-weight-bold' : ''}`} style={{ cursor: 'pointer', alignContent: 'flex-start' }}>fr</div>
            </Link>
            <Link href="/" locale="en">
                <div className={`ml-1 test-first-letter ${locale == 'en' ? 'font-weight-bold' : ''}`} style={{ cursor: 'pointer', alignContent: 'flex-end' }}>en</div>
            </Link>
        </div>
    )
}

export default LanguageSwitch