import { ListingsApi, Configuration, ReviewsApi, LocationsApi } from "typescript-axios"
import axios from "axios"
import haversine from 'haversine-distance'

export const API_URL = process.env.API_HOST
const basePath = typeof window === 'undefined' ? process.env.API_HOST : process.env.NEXT_PUBLIC_HOST

export function getAxiosParams() {
    return new Configuration({ basePath: basePath })
}

const LISTINGS_SERVICE = new ListingsApi(getAxiosParams())
const LOCATIONS_SERVICE = new LocationsApi(getAxiosParams())
const REVIEW_SERVICE = new ReviewsApi(getAxiosParams())

export function callWithoutCache(fun, args) {
    return fun(args, {
        _vercel_no_cache: 1,
        headers: {
            "x-purge-cache": true,
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
        }
    })
}

export async function getListings(params, purgeCache, tiny = false) {
    return await getListingsAsync(params, purgeCache, tiny).then(res => res.data)
}


export async function getListingsAsync({ skip, isOnline, limit, activity, parentActivity, location, dateStart, dateEnd, sortBy, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, options }, purgeCache = false, tiny = false) {
    if (purgeCache) {
        options = options ?? {}
        options.headers = options.headers ?? {}
        options.headers = {
            ...options.headers,
            "x-purge-cache": true,
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
        }
        options.params = { ...options.params, _vercel_no_cache: 1 }
    }

    if(tiny) {
        return LISTINGS_SERVICE.readListingsTinyApiV1ListingsTinyGet(
            skip ?? 0,
            limit ?? 100,
            activity ?? undefined,
            parentActivity ?? undefined,
            (isOnline !== undefined || isOnline !== null) ? isOnline : undefined,
            location ? location : undefined,
            dateStart ? Math.floor(dateStart.unix() / 60) * 60 : undefined,
            dateEnd ? Math.floor(dateEnd.unix() / 60) * 60 : undefined,
            sortBy ?? undefined,
            owner ?? undefined,
            onlyNotFull ?? false,
            onlyNotEmpty ?? false,
            priceMin ?? undefined,
            priceMax ?? undefined,
            options ?? undefined
        )
    } else {
        return LISTINGS_SERVICE.readListingsApiV1ListingsGet(
            skip ?? 0,
            limit ?? 100,
            activity ?? undefined,
            parentActivity ?? undefined,
            (isOnline !== undefined || isOnline !== null) ? isOnline : undefined,
            location ? location : undefined,
            dateStart ? Math.floor(dateStart.unix() / 60) * 60 : undefined,
            dateEnd ? Math.floor(dateEnd.unix() / 60) * 60 : undefined,
            sortBy ?? undefined,
            owner ?? undefined,
            onlyNotFull ?? false,
            onlyNotEmpty ?? false,
            priceMin ?? undefined,
            priceMax ?? undefined,
            options ?? undefined
        )
    }
}



export async function getReviews({ skip, limit, revieweeId, }) {
    return await REVIEW_SERVICE.readReviewsApiV1ReviewsGet(
        skip,
        limit,
        revieweeId
    ).then(res => res.data)
}

export async function getClientLatLng() {
    const res = await axios.get("https://api.ipregistry.co/?key=bxcpxis0lvx7es").then(res => res.data)
    return { lat: res.location.latitude, lng: res.location.longitude }
}

export async function getClientLocation() {
    try {
        const locReq = LOCATIONS_SERVICE.readLocationsApiV1LocationsGet()
        const clientLocaReq = getClientLatLng()
        await axios.all([locReq, clientLocaReq])
        const locs = await locReq.then(res => res.data)
        const clientLoc = await clientLocaReq
        const clientCoord = { latitude: clientLoc.lat, longitude: clientLoc.lng }
        let bestLoc = locs[0]
        let bestDst = 1000000000000000000.0
        locs.map(loc => {
            const dst = haversine(clientCoord, loc)
            if (dst < bestDst) {
                bestLoc = loc
                bestDst = dst
            }
        })
        if (bestDst < 200000) {
            return bestLoc
        }
        return null
    } catch (e) {
        return null
    }
}