import strings from './strings.json'
import ReactHtmlParser from 'react-html-parser'

if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number] : match
    })
  }
}

export function translate(key, locale, defaultLocale, ...args) {
  if (!key)
    return ''
  if (!locale || !strings[locale][key]) {
    console.warn(`Translation '${key}' for locale '${locale}' not found.`)
  }
  locale = locale ?? defaultLocale
  if (!defaultLocale)
    return key
  const s = strings[locale][key] || strings[defaultLocale][key] || ''
  if (args) {
    return s.format(...args)
  }
  return s
}