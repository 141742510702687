import moment from "moment"
import { google } from 'calendar-link'
import { firstLetter } from './boiler';

// for future https://dockyard.com/blog/2020/02/14/you-probably-don-t-need-moment-js-anymore
function pad(v) {
    if (v < 10)
        return `0${v}`;
    return `${v}`;
}

// Next, we need to interpolate values into our localized key
export function formatTimeRange(startDate, duration) {
    const hourStart = startDate.getHours();
    const minuteStart = startDate.getMinutes();

    const endDate = new Date(startDate.getTime() + (duration * 1000));
    const hourEnd = endDate.getHours();
    const minuteEnd = endDate.getMinutes();
    return `${formatTime(hourStart, minuteStart)} - ${formatTime(hourEnd, minuteEnd)}`
}

export function formatTime(hours, minutes) {
    return `${pad(hours)}:${pad(minutes)}`
}

export function formatDate(date, short = false) {
    return moment(date).local().format(short ? "D MMM" : "D MMMM YYYY")
}

export function offsetDate(date) {
    return new Date(moment.utc(date).local())
}

export function getFormattedDateAndRange(listing, short = false) {
    const dateStart = offsetDate(listing.datetime_start)
    const dateStartFormatted = formatDate(dateStart, short)
    const timeRangeFormatted = formatTimeRange(dateStart, listing.duration);
    return { dateStartFormatted, timeRangeFormatted }
}

export function getFormattedDateTime(datetime) {
    const date = offsetDate(datetime)
    const dateFormatted = formatDate(date)
    const time = formatTime(date.getHours(), date.getMinutes())
    return { date: dateFormatted, time }
}

export function getAddToCalendar(listing, t) {
    const date = offsetDate(listing.datetime_start)
    let prepend = `${process.env.NEXT_PUBLIC_HOST}/bookings/${listing.slug}\n\n`
    if (listing.is_online && listing.comment) {
        prepend = `${listing.comment}\n\n`
    }
    const event = {
        start: date,
        duration: [listing.duration, "second"],
        title: `Gurubay: ${firstLetter(t("add_to_calendar", listing.activity.name, listing.owner.firstname))}`,
        description: `${prepend}Gurubay`,
        location: listing.is_online ? 'online' : listing.ppty.address,
        busy: true
    }
    return google(event).replace('http:', '')
}


export function getAddToCalendarOnClick(e, listing, t) {
    e.preventDefault()
    if (typeof window !== 'undefined') {
        const newWindow = window.open(getAddToCalendar(listing, t), '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
}

export function parseTime(t) {
    if (!t)
        return null
    var d = new Date();
    var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
    d.setMinutes(parseInt(time[2]) || 0);
    return d;
}

export function countdown(date, t) {
    date = moment(date)
    const now = moment()
    let diff = date.diff(now, "minutes")
    if (diff < 1)
        return null
    return date.format(t("countdown_pattern"))
}